.sub-cooper-wrap {
  width: 100%;
  height: 100%;
  background: #fff;
}

.sub-cooper {
  display: flex;
  width: 1200px;
  margin: 0 auto;
  background: #fff;
}
.cooper-right {
  width: 810px;
  margin: 110px 0 0 110px;
}
.cooper-right p, .cooper-right ul, .cooper-right ul li{
  line-height: 30px;
}
.cooper-right p{
  margin-bottom: 50px;
}
.cooper-right>h2 {
  font-family: PingFangSC-Medium;
  font-size: 34px;
  color: #333333;
  text-align: left;
}

.time-logo {
  width: 13px;
  height: 13px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  margin-right: 6px;
}

/* p {
  width: 810px;
  color: #333;
  font-size: 16px;
  line-height: 30px;
} */

.pre-next {
  display: block;
  font-size: 14px;
  color: #999;
  font-family: PingFangSC-Medium;
  cursor: pointer;
}

.pre-next:hover {
  color: #19387B;
}
.bgimagesize{
  background-size: contain;
  background-repeat: no-repeat;
  width: 810px;
  height: 380px;
}
.imgFontColor{
  color: rgb(153, 153, 153);
  font-size: 13px;
}
.marginBottom{
  margin-bottom: 15px;
}
.laiyuan{
  margin-left: 30px;
}
.img-laiyuan{
  color: #576b95;
}
.slot-spote li, .spot li{
  list-style: disc;
}
.slot-spote > li{
  color: #ed7d48;
}
.link-color{
  color: #19387B;
}
.pre-next{
  margin-top: 10px;
}

