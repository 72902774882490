.paging-wrap, .paging-numbers{
  display: flex;
  justify-content: center;
  font-size: 16px;
  height: 50px;
  padding: 0;
}
.paging-div{
  width: 50px;
  height: 100%;
  line-height: 50px;
  text-align: center;
  cursor: pointer;
}
.paging-wrap .iconpc-zuojiantou-copy, .paging-wrap .iconpc-youjiantou-copy{
  border-radius: 50%;
  border: 2px solid transparent;
  font-weight: bolder;
}
.paging-wrap .iconpc-zuojiantou-copy:hover, .paging-wrap .iconpc-youjiantou-copy:hover{
  border: 2px solid #333;
}
.isDisable{
  cursor: not-allowed;
  border: 2px solid transparent;
  opacity: 0.35;
  pointer-events: none;
}
.isDisable.iconpc-zuojiantou-copy:hover, .isDisable.iconpc-youjiantou-copy:hover{
  border: 2px solid transparent;
}
