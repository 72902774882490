.cooper-left {
  width: 280px;
  padding-top: 70px;
}

.back {
  font-family: PingFangSC-Medium;
  font-size: 16px;
  color: #333333;
  margin-bottom: 175px;
  cursor: pointer;
  margin-left: -2px;
}

.back>img {
  width: 20px;
  height: 20px;
  margin-bottom: 2px;
  margin-right: 12px;
}

.detail>span{
  font-family: PingFangSC-Medium;
  font-size: 16px;
  color: #333333;
}

.detail-list {
  margin-top: 30px;
  margin-left: 15px;
  padding-left: 0;
}

.detail-item {
  width: 270px;
  height: 40px;
  font-size: 14px;
  margin-bottom: 20px;
  cursor: pointer;
  list-style: disc;
  -webkit-box-orient:vertical !important;
}
.detail-item a {
  height: 40px;
  color: #333;
  overflow:hidden;
  text-overflow:ellipsis;
  display:-webkit-box;
  -webkit-line-clamp:2;
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
}
.detail-item a:hover {
  color: #19387B;
}
