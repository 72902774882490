@import '//at.alicdn.com/t/font_1288056_ddrs9yyudu.css';
@font-face {
  font-family: 'DFPKingGothicGB';
  src: url('./font/DFPKingGothicGB-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: 'AspiraRegular';
  src: url('./font/Aspira-Regular.otf') format('truetype');
  font-style: normal;
  font-weight: normal;
}

ul, li {
  list-style: none
}
body{
  width: 100%;
  min-width: 1200px;
}
button[ant-click-animating-without-extra-node]:after {
  border: 0 none;
  opacity: 0;
  animation: none 0 ease 0 1 normal;
}

.ant-input:hover {
  border-color: #d9d9d9;
}

.ant-input:focus {
  border-color: #19387B;
}

.ant-btn-primary {
  border-color: transparent;
}

.ant-btn-primary:hover {
  border-color: transparent;
  background: '#132E69'
}

.ant-select-dropdown {
  z-index: 100;
}

.ant-select-dropdown-menu-item-active {
  background: #F9F9F9 !important;
  color: #19387B !important;
}

.ant-form-explain {
  position: absolute;
}

.ant-select-dropdown-menu-item-selected {
  font-weight: normal;
  background: #fff;
}

.ant-select-selection:focus, .ant-select-selection:active {
  border-color: #19387B;
  border-right-width: 1px !important;
  outline: none;
  box-shadow: none;
}

.ant-select-selection:hover {
  border-color: #19387B;
}

.ant-btn-primary {
  background: #19387B;
}

.ant-btn-primary:hover {
  background: #132E69;
}

.ant-col-14 {
  width: 986px;
}

.ant-col-9 {
  width: 986px;
}

.side-nabigator {
  position: fixed;
  z-index: 999;
  right: 20px;
  top: 62%;
  background-color: #fff;
  width: 46px;
  height: 100px;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, .1);
  cursor: pointer;
}

.cooperation-icon, .public-icon, .toTop{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
}
.toTop{
  background: #19387B;
  height: 46px;
  margin-top: 6px;
  box-shadow: 0 3px 5px 0 rgba(0,0,0,0.1);
}
.cooperation-icon img, .public-icon img{
  width: 24px;
  height: 24px;
}
.cooperation-text{
  width: 100%;
  height: 50px;
  background-color: #f9f9f9;
  font-size: 12px;
  color: #333;
  text-align: center;
  padding: 5px 7px;
}
.public-wrap{
  position: absolute;
  width: 136px;
  height: 161px;
  background-color: #fff;
  box-shadow: 0 5px 9px 0 rgba(0,0,0,0.1);
  border-radius: 2px 2px;
  left: -153px;
  top: -107px;
  padding: 10px 7px 7px;
}
.triangle{
  position: absolute;
  width: 0;
  height: 0;
  left: 96%;
  top: 80%;
  border: 5px solid #fff;
  border-left-color: transparent;
  border-bottom-color: transparent;
  box-shadow: 5px 5px 10px 0 rgba(0,0,0,.1);
  transform: rotate(45deg);
}
.triangle-text{
  color: #333;
  font-size: 12px;
  text-align: center;
  margin-bottom: 7px;
}
.triangle-img{
  height: 122px;
  background: url('./img/public-icon.jpg') no-repeat;
  background-size: 100%;
}
.product-active:hover {
  color: #ddd;
}
div ul li a Link input span textarea{
  font-family: DFPKingGothicGB
}
/*
!* 更改分页器的样式*!
.par-ai-pagination{
  display: flex;
  justify-content: center;
  margin: 70px auto 71px;
}

.par-ai-pagination .ant-pagination-item, .par-ai-pagination .ant-pagination-item-link, .par-ai-pagination .ant-pagination-prev, .par-ai-pagination .ant-pagination-next{
  width: 36px;
  height: 36px;
  line-height: 36px;
  font-size: 16px;
  margin-right: 0;
  border-radius: 0;
  border: none;
}
.par-ai-pagination .ant-pagination-item, .par-ai-pagination .ant-pagination-item-link{
  border-right: 1px solid #eee;
}
.par-ai-pagination .ant-pagination-item a{
  color: #090909;
}
.par-ai-pagination .ant-pagination-item-active{
  border-width: 0 1px 0 0;
  background: #DBDADA;
}
.par-ai-pagination .ant-pagination-item-active a{
  color: #060606;
}
.par-ai-pagination .ant-pagination-item:focus a, .par-ai-pagination .ant-pagination-item:hover a{
  color: #19387B;
}
.par-ai-pagination .ant-pagination-prev:focus .ant-pagination-item-link, .par-ai-pagination .ant-pagination-next:focus .ant-pagination-item-link, .par-ai-pagination .ant-pagination-prev:hover .ant-pagination-item-link, .par-ai-pagination .ant-pagination-next:hover .ant-pagination-item-link{
  border-right:1px solid #eee;
  color: #333;
}
!*更改分页器样式结束*!*/


