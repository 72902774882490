.nav-header-wrap{
  width: 100%;
  height: 50px;
  position: fixed;
  z-index: 999;
  background-color: #0C101A;
  color: #fff;
  font-size: 16px;
  transition: background .4s ease;
  font-family: DFPKingGothicGB
}
.nav-header-inner{
  width: 1200px;
  margin:0 auto;
  height: 100%;
}
.nav-navigation{
  height: 100%;
  float: right;
}
.nav-header-wrap .icon{
  display: inline-block;
  margin-left: 6px;
}
.nav-header-wrap:hover{
  background-color: #fff;
  transition: background .4s ease;
}
.nav-header-wrap:hover .nav-logo{
  background-image: url('./img/logo.svg');
}
.nav-header-wrap:hover .nav-item-text, .nav-header-wrap:hover .icon{
  color: #0c101a;
}
.nav-logo{
  width: 180px;
  height: 50px;
  float: left;
  margin: 0 40px 0 0;
  background-image: url('./img/logo1.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  cursor: pointer;
}
.nav-navigation-item{
  width: 140px;
  text-align: center;
  height: 100%;
  font-size: 16px;
  cursor: pointer;
  float: left;
  color: #fff;
}
.nav-navigation-item:nth-child(n + 2) {
  position: relative;
}
.nav-item-text{
  height: 100%;
  color: #fff;
  line-height: 50px;
}
.nav-navigation-item:hover .nav-item-text{
  color: #19387b;
}
.nav-navigation-item:hover .iconzhankaidown{
  transition: transform 0.4s;
  transform: rotate(180deg);
  color: #19387b;
}
.nav-navigation-item:hover .nav-table{
  transition: transform .2s ease-out;
  transform: scaleY(1);
}
.nav-table{
  position: absolute;
  padding: 30px 80px;
  background-color: rgba(255,255,255,0.95);
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  transition: transform .2s ease-out;
  transform: scaleY(0);
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, .1);
  transform-origin: left top;
}
.ecological .nav-table{
  padding: 10px 40px 30px;
}
.solove-top .nav-table{
  padding: 30px 40px;
}
.nav-table.is-nav-show{
  transform: scaleY(0) !important;
}
.table-model-title{
  color: #19387B;
  margin-bottom: 10px;
  cursor: default;
}
.table-model-list{
  padding: 19px 0 0 25px;
}
.table-model-list:nth-child(1) {
  padding-left: 0;
}
@media screen and (min-width: 1200px) and (max-width: 1780px) {
  .server-wrap .nav-table{
    padding: 30px 80px;
  }
  .server-wrap .table-model:last-child{
    position: absolute;
    right: 80px;
    top: 40%;
  }
}
@media screen and (min-width: 1780px) and (max-width: 1920px) {
  .server-wrap .nav-table{
    padding: 30px 300px;
  }
  .server-wrap .table-model:last-child{
    position: absolute;
    right: 300px;
    top: 40%;
  }
}
.model-item-title{
  display: block;
  color: #333;
  font-family: PingFangSC-Medium;
  font-size: 16px;
  margin-bottom: 16px;
  width: 120px;
}
.list-name{
  font-size: 14px;
  color: #555;
  padding-left: 10px;
  display: inline-block;
  width: 100%;
  height: 100%;
}
.title-list-name{
  height: 26px;
  line-height: 26px;
  margin: 0 0 5px -10px;
}
.no-pointer{
  cursor: default;
}
.no-pointer .list-name, .model-item-title.no-pointer{
  pointer-events: none;
}
.title-list-name.no-pointer:hover{
  background-color: transparent;
}
.title-list-name.no-pointer:hover .list-name{
  color: #555;
}
.title-list-name:hover{
  background-color: #eaeaea;
}
.title-list-name:hover .list-name, .model-item-title:hover{
  color: #19387b;
}
.title-list-name:last-child{
  margin-bottom: 32px;
}
.table-model{
  text-align: left;
}

.table-class{
  display: -webkit-flex;
  display: flex;
  border-top: 1px solid rgba(25,56,123,0.3);
}
.nav-hot, .nav-disable{
  display: inline-block;
  width: 32px;
  height: 15px;
  line-height: 15px;
  text-align: center;
  color: #fff;
  background: #EB612A;
  font-size: 9px;
  margin-left: 2px;
  transform: scale(0.9) translateY(-1px);
}
.nav-disable{
  position: absolute;
  display: inline-block;
  width: 60px;
  text-align: center;
  color: #fff;
  background: #19387B;
  transform: scale(0.9) translateY(6px);
}
.margin-bottom2{
  margin-bottom: -2px;
}
.nav-button{
  display: block;
  width: 110px;
  height: 30px;
  font-size: 16px;
  color: #fff;
  text-align: center;
  line-height: 28px;
  border: 1px solid #fff;
  border-radius: 2px;
  float: left;
  margin: 10px 0 0 5px;
  cursor: pointer;
}
.nav-header-wrap:hover .nav-button{
  color: #0C101A;
  border: 1px solid #0C101A;
}
.nav-button-span .nav-button:hover{
  color: #fff;
  border: 1px solid #19387B;
  background-color: #19387B;
}

