@media (min-width: 1366px) {
  html {
    font-size: 12px;
  }
}

@media (min-width: 1920px) {
  html {
    font-size: 16px;
  }
}
a:link {
  text-decoration: none;
}
a:visited {
  text-decoration: none;
}
a:active {
  text-decoration: none;
}
.submenu-title-wrapper:hover {
  color: #f77b4c;
}

.m_mask .left {
  float: left;
  width: 20%;
  height: 100%;
  opacity: 1;
  background: "#3D3F4A";
}

.m_mask .left > ul {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: #f6f6f6;
}

.m_mask .left > ul > li {
  font-size: 15px;
  margin-bottom: 40px;
  cursor: pointer;
}

.m_mask .left > ul > li:hover {
  color: #f77b4c;
}

.m_mask .right {
  width: 80%;
  height: 100%;
  background: #41434d;
  float: left;
  padding: 20px 0 0 25px;
}
.m_mask .right .cateItem {
  margin-bottom: 35px;
}
.m_mask .right ul {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 0;
  color: #6c6e7a;
}

.m_mask .right ul li {
  margin-right: 22px;
  margin-bottom: 10px;
  cursor: pointer;
  width: 90px;
  height: 15px;
  font-size: 14px !important;
}

a {
  color: #adadad;
}

.pro-active:hover {
  color: #f77b4c !important;
}

em,
i {
  font-style: normal;
}
