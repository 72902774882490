@import url(//at.alicdn.com/t/font_1288056_ddrs9yyudu.css);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

i, cite, em, var, address, dfn {
	font-style: none;
}

.footer-msg-wrap {
  position: relative;
  width: 100vw;
  height: 51.48148148vh;
  background: #0C101A;
}
.footer-inner {
  position: relative;
  width: 79%;
  height: 100%;
  margin-left: 10.5%;
}
.footer-msg {
  position: relative;
  width: 100%;
  padding-top: 9.44444444vh;
  overflow: hidden;
}
.footer-logo {
  width: 100%;
  overflow: hidden;
}
.logo {
  width: 22.59259259vh;
  height: 2.96296296vh;
  float: left;
  background: url(/static/media/footer-msg-logo.d31d5301.png) no-repeat;
  background-size: contain;
}
.logo-title {
  float: right;
  color: #fff;
  height: 2.96296296vh;
  line-height: 2.96296296vh;
  font-size: 1.5rem;
}
.line {
  width: 100%;
  height: 0.09259259vh;
  background-color: #4e4e4e;
  margin: 2.5vh 0 3.24074074vh;
}
.text {
  float: left;
}
.contact-us {
  margin-right: 6.48148148vh;
}
.contact-list {
  margin-top: 1.38888889vh;
}
.item-list {
  display: block;
  color: #adadad;
  font-size: 0.875rem;
  cursor: pointer;
  line-height: 1.625rem;
}
.item-list:hover {
  color: #fff;
}
.iconfanhuishouye {
  font-size: 1.5rem;
  color: #fff;
}
.font-size {
  font-size: 1rem;
  color: #fff;
}
.friend {
  float: left;
}
.public {
  float: right;
}
.puclic-img {
  width: 9.81481481vh;
  height: 9.81481481vh;
  margin-bottom: 4.07407407vh;
}
.public-title {
  font-size: 1.125rem;
  color: #adadad;
  margin-bottom: 1.75925926vh;
}
.didi-message {
  font-size: 0.875rem;
  color: #adadad;
}
.didi-message {
  text-align: left;
}

.nav-header-wrap{
  width: 100%;
  height: 50px;
  position: fixed;
  z-index: 999;
  background-color: #0C101A;
  color: #fff;
  font-size: 16px;
  transition: background .4s ease;
  font-family: DFPKingGothicGB
}
.nav-header-inner{
  width: 1200px;
  margin:0 auto;
  height: 100%;
}
.nav-navigation{
  height: 100%;
  float: right;
}
.nav-header-wrap .icon{
  display: inline-block;
  margin-left: 6px;
}
.nav-header-wrap:hover{
  background-color: #fff;
  transition: background .4s ease;
}
.nav-header-wrap:hover .nav-logo{
  background-image: url(/static/media/logo.64880ed8.svg);
}
.nav-header-wrap:hover .nav-item-text, .nav-header-wrap:hover .icon{
  color: #0c101a;
}
.nav-logo{
  width: 180px;
  height: 50px;
  float: left;
  margin: 0 40px 0 0;
  background-image: url(/static/media/logo1.e3691858.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  cursor: pointer;
}
.nav-navigation-item{
  width: 140px;
  text-align: center;
  height: 100%;
  font-size: 16px;
  cursor: pointer;
  float: left;
  color: #fff;
}
.nav-navigation-item:nth-child(n + 2) {
  position: relative;
}
.nav-item-text{
  height: 100%;
  color: #fff;
  line-height: 50px;
}
.nav-navigation-item:hover .nav-item-text{
  color: #19387b;
}
.nav-navigation-item:hover .iconzhankaidown{
  transition: transform 0.4s;
  transform: rotate(180deg);
  color: #19387b;
}
.nav-navigation-item:hover .nav-table{
  transition: transform .2s ease-out;
  transform: scaleY(1);
}
.nav-table{
  position: absolute;
  padding: 30px 80px;
  background-color: rgba(255,255,255,0.95);
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  transition: transform .2s ease-out;
  transform: scaleY(0);
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, .1);
  transform-origin: left top;
}
.ecological .nav-table{
  padding: 10px 40px 30px;
}
.solove-top .nav-table{
  padding: 30px 40px;
}
.nav-table.is-nav-show{
  transform: scaleY(0) !important;
}
.table-model-title{
  color: #19387B;
  margin-bottom: 10px;
  cursor: default;
}
.table-model-list{
  padding: 19px 0 0 25px;
}
.table-model-list:nth-child(1) {
  padding-left: 0;
}
@media screen and (min-width: 1200px) and (max-width: 1780px) {
  .server-wrap .nav-table{
    padding: 30px 80px;
  }
  .server-wrap .table-model:last-child{
    position: absolute;
    right: 80px;
    top: 40%;
  }
}
@media screen and (min-width: 1780px) and (max-width: 1920px) {
  .server-wrap .nav-table{
    padding: 30px 300px;
  }
  .server-wrap .table-model:last-child{
    position: absolute;
    right: 300px;
    top: 40%;
  }
}
.model-item-title{
  display: block;
  color: #333;
  font-family: PingFangSC-Medium;
  font-size: 16px;
  margin-bottom: 16px;
  width: 120px;
}
.list-name{
  font-size: 14px;
  color: #555;
  padding-left: 10px;
  display: inline-block;
  width: 100%;
  height: 100%;
}
.title-list-name{
  height: 26px;
  line-height: 26px;
  margin: 0 0 5px -10px;
}
.no-pointer{
  cursor: default;
}
.no-pointer .list-name, .model-item-title.no-pointer{
  pointer-events: none;
}
.title-list-name.no-pointer:hover{
  background-color: transparent;
}
.title-list-name.no-pointer:hover .list-name{
  color: #555;
}
.title-list-name:hover{
  background-color: #eaeaea;
}
.title-list-name:hover .list-name, .model-item-title:hover{
  color: #19387b;
}
.title-list-name:last-child{
  margin-bottom: 32px;
}
.table-model{
  text-align: left;
}

.table-class{
  display: flex;
  border-top: 1px solid rgba(25,56,123,0.3);
}
.nav-hot, .nav-disable{
  display: inline-block;
  width: 32px;
  height: 15px;
  line-height: 15px;
  text-align: center;
  color: #fff;
  background: #EB612A;
  font-size: 9px;
  margin-left: 2px;
  transform: scale(0.9) translateY(-1px);
}
.nav-disable{
  position: absolute;
  display: inline-block;
  width: 60px;
  text-align: center;
  color: #fff;
  background: #19387B;
  transform: scale(0.9) translateY(6px);
}
.margin-bottom2{
  margin-bottom: -2px;
}
.nav-button{
  display: block;
  width: 110px;
  height: 30px;
  font-size: 16px;
  color: #fff;
  text-align: center;
  line-height: 28px;
  border: 1px solid #fff;
  border-radius: 2px;
  float: left;
  margin: 10px 0 0 5px;
  cursor: pointer;
}
.nav-header-wrap:hover .nav-button{
  color: #0C101A;
  border: 1px solid #0C101A;
}
.nav-button-span .nav-button:hover{
  color: #fff;
  border: 1px solid #19387B;
  background-color: #19387B;
}



.actived:hover {
    color: #132E69 !important;
}
.sub-cooper-wrap {
  width: 100%;
  height: 100%;
  background: #fff;
}

.sub-cooper {
  display: flex;
  width: 1200px;
  margin: 0 auto;
  background: #fff;
}
.cooper-right {
  width: 810px;
  margin: 110px 0 0 110px;
}
.cooper-right p, .cooper-right ul, .cooper-right ul li{
  line-height: 30px;
}
.cooper-right p{
  margin-bottom: 50px;
}
.cooper-right>h2 {
  font-family: PingFangSC-Medium;
  font-size: 34px;
  color: #333333;
  text-align: left;
}

.time-logo {
  width: 13px;
  height: 13px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  margin-right: 6px;
}

/* p {
  width: 810px;
  color: #333;
  font-size: 16px;
  line-height: 30px;
} */

.pre-next {
  display: block;
  font-size: 14px;
  color: #999;
  font-family: PingFangSC-Medium;
  cursor: pointer;
}

.pre-next:hover {
  color: #19387B;
}
.bgimagesize{
  background-size: contain;
  background-repeat: no-repeat;
  width: 810px;
  height: 380px;
}
.imgFontColor{
  color: rgb(153, 153, 153);
  font-size: 13px;
}
.marginBottom{
  margin-bottom: 15px;
}
.laiyuan{
  margin-left: 30px;
}
.img-laiyuan{
  color: #576b95;
}
.slot-spote li, .spot li{
  list-style: disc;
}
.slot-spote > li{
  color: #ed7d48;
}
.link-color{
  color: #19387B;
}
.pre-next{
  margin-top: 10px;
}


.cooper-left {
  width: 280px;
  padding-top: 70px;
}

.back {
  font-family: PingFangSC-Medium;
  font-size: 16px;
  color: #333333;
  margin-bottom: 175px;
  cursor: pointer;
  margin-left: -2px;
}

.back>img {
  width: 20px;
  height: 20px;
  margin-bottom: 2px;
  margin-right: 12px;
}

.detail>span{
  font-family: PingFangSC-Medium;
  font-size: 16px;
  color: #333333;
}

.detail-list {
  margin-top: 30px;
  margin-left: 15px;
  padding-left: 0;
}

.detail-item {
  width: 270px;
  height: 40px;
  font-size: 14px;
  margin-bottom: 20px;
  cursor: pointer;
  list-style: disc;
  -webkit-box-orient:vertical !important;
}
.detail-item a {
  height: 40px;
  color: #333;
  overflow:hidden;
  text-overflow:ellipsis;
  display:-webkit-box;
  -webkit-line-clamp:2;
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
}
.detail-item a:hover {
  color: #19387B;
}

.paging-wrap, .paging-numbers{
  display: flex;
  justify-content: center;
  font-size: 16px;
  height: 50px;
  padding: 0;
}
.paging-div{
  width: 50px;
  height: 100%;
  line-height: 50px;
  text-align: center;
  cursor: pointer;
}
.paging-wrap .iconpc-zuojiantou-copy, .paging-wrap .iconpc-youjiantou-copy{
  border-radius: 50%;
  border: 2px solid transparent;
  font-weight: bolder;
}
.paging-wrap .iconpc-zuojiantou-copy:hover, .paging-wrap .iconpc-youjiantou-copy:hover{
  border: 2px solid #333;
}
.isDisable{
  cursor: not-allowed;
  border: 2px solid transparent;
  opacity: 0.35;
  pointer-events: none;
}
.isDisable.iconpc-zuojiantou-copy:hover, .isDisable.iconpc-youjiantou-copy:hover{
  border: 2px solid transparent;
}

.Try {
    text-align: center;
  }
  
  .Try-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
  }
  
  .Try-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .Try-link {
    color: #61dafb;
  }
  
  @keyframes Try-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .gutter-example .ant-row > div {
    background: transparent;
    border: 0;
  }
  
  .gutter-box {
    background: #00A0E9;
    padding: 5px 0;
    height: 50px;
  }
  
  .original img{
    width: auto;
    height: auto;
    max-height: 100%;
    object-fit:contain;
    background: #eee;
  }

.thumbnailT {
  height: 100px;
  width: 300px;
  vertical-align: middle;
  text-align: center;
  display: table-cell;
  border-width: 1px;
}
  
.thumbnail img{
  height: 90px;
  width: auto;
}

@font-face {
  font-family: 'DFPKingGothicGB';
  src: url(/static/media/DFPKingGothicGB-Regular.d754e186.ttf) format('truetype');
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: 'AspiraRegular';
  src: url(/static/media/Aspira-Regular.7ff70643.otf) format('truetype');
  font-style: normal;
  font-weight: normal;
}

ul, li {
  list-style: none
}
body{
  width: 100%;
  min-width: 1200px;
}
button[ant-click-animating-without-extra-node]:after {
  border: 0 none;
  opacity: 0;
  animation: none 0 ease 0 1 normal;
}

.ant-input:hover {
  border-color: #d9d9d9;
}

.ant-input:focus {
  border-color: #19387B;
}

.ant-btn-primary {
  border-color: transparent;
}

.ant-btn-primary:hover {
  border-color: transparent;
  background: '#132E69'
}

.ant-select-dropdown {
  z-index: 100;
}

.ant-select-dropdown-menu-item-active {
  background: #F9F9F9 !important;
  color: #19387B !important;
}

.ant-form-explain {
  position: absolute;
}

.ant-select-dropdown-menu-item-selected {
  font-weight: normal;
  background: #fff;
}

.ant-select-selection:focus, .ant-select-selection:active {
  border-color: #19387B;
  border-right-width: 1px !important;
  outline: none;
  box-shadow: none;
}

.ant-select-selection:hover {
  border-color: #19387B;
}

.ant-btn-primary {
  background: #19387B;
}

.ant-btn-primary:hover {
  background: #132E69;
}

.ant-col-14 {
  width: 986px;
}

.ant-col-9 {
  width: 986px;
}

.side-nabigator {
  position: fixed;
  z-index: 999;
  right: 20px;
  top: 62%;
  background-color: #fff;
  width: 46px;
  height: 100px;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, .1);
  cursor: pointer;
}

.cooperation-icon, .public-icon, .toTop{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
}
.toTop{
  background: #19387B;
  height: 46px;
  margin-top: 6px;
  box-shadow: 0 3px 5px 0 rgba(0,0,0,0.1);
}
.cooperation-icon img, .public-icon img{
  width: 24px;
  height: 24px;
}
.cooperation-text{
  width: 100%;
  height: 50px;
  background-color: #f9f9f9;
  font-size: 12px;
  color: #333;
  text-align: center;
  padding: 5px 7px;
}
.public-wrap{
  position: absolute;
  width: 136px;
  height: 161px;
  background-color: #fff;
  box-shadow: 0 5px 9px 0 rgba(0,0,0,0.1);
  border-radius: 2px 2px;
  left: -153px;
  top: -107px;
  padding: 10px 7px 7px;
}
.triangle{
  position: absolute;
  width: 0;
  height: 0;
  left: 96%;
  top: 80%;
  border: 5px solid #fff;
  border-left-color: transparent;
  border-bottom-color: transparent;
  box-shadow: 5px 5px 10px 0 rgba(0,0,0,.1);
  transform: rotate(45deg);
}
.triangle-text{
  color: #333;
  font-size: 12px;
  text-align: center;
  margin-bottom: 7px;
}
.triangle-img{
  height: 122px;
  background: url(/static/media/public-icon.7f3cf8ee.jpg) no-repeat;
  background-size: 100%;
}
.product-active:hover {
  color: #ddd;
}
div ul li a Link input span textarea{
  font-family: DFPKingGothicGB
}
/*
!* 更改分页器的样式*!
.par-ai-pagination{
  display: flex;
  justify-content: center;
  margin: 70px auto 71px;
}

.par-ai-pagination .ant-pagination-item, .par-ai-pagination .ant-pagination-item-link, .par-ai-pagination .ant-pagination-prev, .par-ai-pagination .ant-pagination-next{
  width: 36px;
  height: 36px;
  line-height: 36px;
  font-size: 16px;
  margin-right: 0;
  border-radius: 0;
  border: none;
}
.par-ai-pagination .ant-pagination-item, .par-ai-pagination .ant-pagination-item-link{
  border-right: 1px solid #eee;
}
.par-ai-pagination .ant-pagination-item a{
  color: #090909;
}
.par-ai-pagination .ant-pagination-item-active{
  border-width: 0 1px 0 0;
  background: #DBDADA;
}
.par-ai-pagination .ant-pagination-item-active a{
  color: #060606;
}
.par-ai-pagination .ant-pagination-item:focus a, .par-ai-pagination .ant-pagination-item:hover a{
  color: #19387B;
}
.par-ai-pagination .ant-pagination-prev:focus .ant-pagination-item-link, .par-ai-pagination .ant-pagination-next:focus .ant-pagination-item-link, .par-ai-pagination .ant-pagination-prev:hover .ant-pagination-item-link, .par-ai-pagination .ant-pagination-next:hover .ant-pagination-item-link{
  border-right:1px solid #eee;
  color: #333;
}
!*更改分页器样式结束*!*/



div :active{
    borderColor:'#F77B4C'
}

.t-hover-shadow {
    transition: transform .3s ease-in-out, box-shadow .3s cubic-bezier(.47, 0, .745, .715), border .3s linear .1s;
}

.t-hover-shadow:hover {
    box-shadow: 0 10px 50px rgba(51, 51, 51, .25);
    transform: translateY(-10px)
}
.t-hover-shadow {
    transition: transform .3s ease-in-out, box-shadow .3s cubic-bezier(.47, 0, .745, .715), border .3s linear .1s;
}

.t-hover-shadow:hover {
    box-shadow: 0 10px 50px rgba(51, 51, 51, .25);
    transform: translateY(-10px)
}

.ant-select-dropdown-menu-item {
  height: 46px;
  line-height: 36px;
}

input:-webkit-autofill { box-shadow: 0 0 0px 1000px white inset !important;}

.ant-select-dropdown {
  box-shadow: 0 20px 30px rgba(0,0,0,0.15) !important;
}
@media (min-width: 1366px) {
  html {
    font-size: 12px;
  }
}

@media (min-width: 1920px) {
  html {
    font-size: 16px;
  }
}
a:link {
  text-decoration: none;
}
a:visited {
  text-decoration: none;
}
a:active {
  text-decoration: none;
}
.submenu-title-wrapper:hover {
  color: #f77b4c;
}

.m_mask .left {
  float: left;
  width: 20%;
  height: 100%;
  opacity: 1;
  background: "#3D3F4A";
}

.m_mask .left > ul {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: #f6f6f6;
}

.m_mask .left > ul > li {
  font-size: 15px;
  margin-bottom: 40px;
  cursor: pointer;
}

.m_mask .left > ul > li:hover {
  color: #f77b4c;
}

.m_mask .right {
  width: 80%;
  height: 100%;
  background: #41434d;
  float: left;
  padding: 20px 0 0 25px;
}
.m_mask .right .cateItem {
  margin-bottom: 35px;
}
.m_mask .right ul {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 0;
  color: #6c6e7a;
}

.m_mask .right ul li {
  margin-right: 22px;
  margin-bottom: 10px;
  cursor: pointer;
  width: 90px;
  height: 15px;
  font-size: 14px !important;
}

a {
  color: #adadad;
}

.pro-active:hover {
  color: #f77b4c !important;
}

em,
i {
  font-style: normal;
}

