@pxTovh: 100/1080vh;
@scale: 12/192rem;

.footer-msg-wrap{
  position: relative;
  width: 100vw;
  height: 556*@pxTovh;
  background: #0C101A;
}
.footer-inner{
  position: relative;
  width: 79%;
  height: 100%;
  margin-left: 10.5%;
}
.footer-msg{
  position: relative;
  width: 100%;
  padding-top: 102*@pxTovh;
  overflow: hidden;
}
.footer-logo{
  width: 100%;
  overflow: hidden;
}
.logo{
  width: 244*@pxTovh;
  height: 32*@pxTovh;
  float: left;
  background: url("./footer-msg-logo.png") no-repeat;
  background-size: contain;
}
.logo-title{
  float: right;
  color: #fff;
  height: 32*@pxTovh;
  line-height: 32*@pxTovh;
  font-size: 24*@scale;
}
.line{
  width: 100%;
  height: 1*@pxTovh;
  background-color: #4e4e4e;
  margin: 27*@pxTovh 0 35*@pxTovh;
}
.text{
  float: left;
}
.contact-us{
  margin-right: 70*@pxTovh;
}
.contact-list{
  margin-top: 15*@pxTovh;
}
.item-list{
  display: block;
  color: #adadad;
  font-size: 14*@scale;
  cursor: pointer;
  line-height: 26*@scale;
}
.item-list:hover{
  color: #fff;
}
.iconfanhuishouye{
  font-size: 24*@scale;
  color: #fff;
}
.font-size{
  font-size: 16*@scale;
  color: #fff;
}
.friend{
  float: left;
}
.public{
  float: right;
}
.puclic-img{
  width: 106*@pxTovh;
  height: 106*@pxTovh;
  margin-bottom: 44*@pxTovh;
}
.public-title{
  font-size: 18*@scale;
  color: #adadad;
  margin-bottom: 19*@pxTovh;
}
.didi-message{
  font-size: 14*@scale;
  color: #adadad;
}
.didi-message{
  text-align: left;
}
