.Try {
    text-align: center;
  }
  
  .Try-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
  }
  
  .Try-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .Try-link {
    color: #61dafb;
  }
  
  @keyframes Try-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .gutter-example .ant-row > div {
    background: transparent;
    border: 0;
  }
  
  .gutter-box {
    background: #00A0E9;
    padding: 5px 0;
    height: 50px;
  }
  
  .original img{
    width: auto;
    height: auto;
    max-height: 100%;
    object-fit:contain;
    background: #eee;
  }

.thumbnailT {
  height: 100px;
  width: 300px;
  vertical-align: middle;
  text-align: center;
  display: table-cell;
  border-width: 1px;
}
  
.thumbnail img{
  height: 90px;
  width: auto;
}
